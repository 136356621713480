// Generated by Framer (78454af)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["FIRVBd7ku"];

const serializationHash = "framer-9f82v"

const variantClassNames = {FIRVBd7ku: "framer-v-1g6690i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FIRVBd7ku", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1g6690i", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"FIRVBd7ku"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-15qcmcp"} data-framer-name={"Icon"} fill={"rgba(0,0,0,1)"} intrinsicHeight={20} intrinsicWidth={20} layoutDependency={layoutDependency} layoutId={"I2171:888;38:423;30:33"} svg={"<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M12.5 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H12.5\" stroke=\"#B7B1BE\" stroke-width=\"1.66667\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M8.33337 14.1667L12.5 10L8.33337 5.83334\" stroke=\"#B7B1BE\" stroke-width=\"1.66667\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M12.5 10H2.5\" stroke=\"#B7B1BE\" stroke-width=\"1.66667\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>\n"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9f82v.framer-9a6luh, .framer-9f82v .framer-9a6luh { display: block; }", ".framer-9f82v.framer-1g6690i { height: 20px; overflow: visible; position: relative; width: 20px; }", ".framer-9f82v .framer-15qcmcp { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerIJ6PDxY3Q: React.ComponentType<Props> = withCSS(Component, css, "framer-9f82v") as typeof Component;
export default FramerIJ6PDxY3Q;

FramerIJ6PDxY3Q.displayName = "Login Icon";

FramerIJ6PDxY3Q.defaultProps = {height: 20, width: 20};

addFonts(FramerIJ6PDxY3Q, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})